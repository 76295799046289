
import { defineComponent } from "vue";
// components
import FormModal from "@/components/modals/FormModal.vue";
import WorkAreaForm from "@/features/workAreas/WorkAreaForm.vue";

export default defineComponent({
  name: "WorkAreaFormModal",
  components: {
    FormModal,
    WorkAreaForm
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  emits: { close: null },
  setup() {
    return {};
  }
});
