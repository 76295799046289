
import { defineComponent, ref } from "vue";
import PageLayout from "@/components/PageLayout.vue";
import WorkAreaFormModal from "@/features/workAreas/WorkAreaFormModal.vue";
import WorkAreaTable from "@/features/workAreas/table/WorkAreaTable.vue";
// store
import useWorkAreaStore from "@/store/useWorkAreaStore";

export default defineComponent({
  name: "WorkAreasPage",
  components: {
    PageLayout,
    WorkAreaFormModal,
    WorkAreaTable
  },
  setup() {
    const headerText = "Work Areas";

    const { workAreas } = useWorkAreaStore({ allWorkAreas: true });

    const formVisible = ref(false);

    return {
      headerText,
      workAreas,
      formVisible
    };
  }
});
