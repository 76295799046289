<template>
  <PageLayout :headerText="headerText">
    <template v-slot:header>
      <div class="flex justify-end">
        <BaseButton @click="formVisible = true">Create work area</BaseButton>
      </div>
      <WorkAreaFormModal @close="formVisible = false" :visible="formVisible" />
    </template>
    <template v-slot:main>
      <WorkAreaTable class="mt-2" :workAreas="workAreas" />
    </template>
  </PageLayout>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import PageLayout from "@/components/PageLayout.vue";
import WorkAreaFormModal from "@/features/workAreas/WorkAreaFormModal.vue";
import WorkAreaTable from "@/features/workAreas/table/WorkAreaTable.vue";
// store
import useWorkAreaStore from "@/store/useWorkAreaStore";

export default defineComponent({
  name: "WorkAreasPage",
  components: {
    PageLayout,
    WorkAreaFormModal,
    WorkAreaTable
  },
  setup() {
    const headerText = "Work Areas";

    const { workAreas } = useWorkAreaStore({ allWorkAreas: true });

    const formVisible = ref(false);

    return {
      headerText,
      workAreas,
      formVisible
    };
  }
});
</script>
