<template>
  <form class="p-1">
    <div class="flex w-full space-x-6">
      <BaseInput
        v-model="formData.name"
        :name="'name'"
        :label="'Name'"
        :validate="validate"
        :schema="nameSchema"
        :focus="false"
      />
    </div>
    <div class="flex pt-2 space-x-2">
      <BaseButton @btn-click="onClose" :variant="'default'">Cancel</BaseButton>
      <BaseButton form="workArea-form" :variant="'primary'" :loading="loading" @btn-click="onSubmit">Create</BaseButton>
    </div>
    <div v-if="error" class="text-red-600">{{ error }}</div>
  </form>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseInput from "@/components/base/BaseInput.vue";
// store
import useWorkAreaStore, { createAbbreviation } from "@/store/useWorkAreaStore";
// other
import { getRandomColor } from "@/utils/colorHelpers";
import useWorkAreaForm from "./useWorkAreaForm";

export default defineComponent({
  name: "WorkAreaForm",
  components: {
    BaseInput
  },
  emits: { close: null },
  setup(props, context) {
    // store
    const { onCreateWorkArea, onCreateWorkAreaSuccess, loading, error } = useWorkAreaStore();

    // workArea form hook
    const { formData, resetForm, workAreaSchema, validate, nameSchema } = useWorkAreaForm({
      name: "",
      abbreviation: "",
      color: ""
    });

    // Validate and create workArea
    const onSubmit = () => {
      // create abbreviaiton and color
      formData.abbreviation = createAbbreviation(formData.name);
      formData.color = getRandomColor();
      workAreaSchema
        .validate(formData)
        .then(() => {
          // execute api call
          onCreateWorkArea(formData.name, formData.abbreviation, formData.color);
        })
        .catch(() => (validate.value = true));
    };

    // close form and reset data
    const onClose = () => {
      validate.value = false;
      resetForm();
      context.emit("close");
    };

    // cleanup after successful action
    onCreateWorkAreaSuccess(() => {
      console.info("WorkArea successfully created");
      onClose();
    });

    return {
      formData,
      validate,
      nameSchema,
      onSubmit,
      onClose,
      loading,
      error
    };
  }
});
</script>

<style></style>
