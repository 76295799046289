<template>
  <div class="flex flex-col flex-1 overflow-hidden">
    <header class="bg-white shadow-sm border-t">
      <div class="inline-flex justify-between w-full mx-auto py-4" :class="pageSize">
        <div class="inline-flex">
          <slot name="header-addon-left"></slot>
          <h1 class="text-lg leading-6 font-semibold text-gray-900 flex items-center">
            {{ headerText }}
          </h1>
          <slot name="header-addon-right"></slot>
        </div>

        <slot name="header"></slot>
      </div>
    </header>
    <main class="flex flex-1 overflow-hidden">
      <div class="flex flex-auto overflow-hidden" :class="pageSize">
        <div class="pt-2 pb-6 sm:px-0 flex flex-auto overflow-hidden">
          <slot name="main"></slot>
        </div>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageLayout",
  components: {},
  props: {
    headerText: {
      type: String
    }
  },
  setup() {
    const pageSize = "mx-auto px-4 sm:pl-6 sm:pr-6";
    return {
      pageSize
    };
  }
});
</script>
