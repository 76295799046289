
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageLayout",
  components: {},
  props: {
    headerText: {
      type: String
    }
  },
  setup() {
    const pageSize = "mx-auto px-4 sm:pl-6 sm:pr-6";
    return {
      pageSize
    };
  }
});
